import React, { Component } from 'react';
import { hot } from 'react-hot-loader'


import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class StreamInput extends Component {

	render () {
		const id = this.props.id
		const elementID = `StreamURL${this.props.id}`
		return (
			<Form inline>
			<InputGroup  style={{ paddingLeft: 5, paddingRight: 5 }}>
			<InputGroup.Prepend>				
				<OverlayTrigger placement="bottom" show={id === 1 && this.props.shouldShowHelp} overlay={(<Tooltip id="help-tooltip">Shows which stream is currently playing audio. Hover over a stream video to hear it. Click this to 'lock' audio to a stream. Click this again to go back to hover mode</Tooltip>)}>			
					<Button 
						type="button" 
						variant={this.props.clickedTile === id ? "primary" : this.props.selectedTile === id ? "secondary": "outline-secondary"} 
						id="button1" 
						onClick={() => this.props.handleClickTile(id)} 
					>{id}</Button>
				</OverlayTrigger>
			</InputGroup.Prepend>
			
				<OverlayTrigger placement="bottom" show={id === 3 && this.props.shouldShowHelp} overlay={(<Tooltip id="help-tooltip">PUT YO STREAM LINKS IN THESE. Supports YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion</Tooltip>)}>	
					<FormControl
						placeholder="Stream URL" 
						aria-label={elementID}
						aria-describedby="basic-addon1"
						id={elementID}
						type="input"
						onKeyPress={event => {
							if (event.key === "Enter") {
								event.preventDefault();
								this.props.setStreamUrl(this.props.id, document.getElementById(`${elementID}`).value)
							}
						}}
					/>
			
				</OverlayTrigger>
			</InputGroup>
		</Form>
		)
  }
}

export default hot(module)(StreamInput)
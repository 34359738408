import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { useParams } from "react-router";


import MainView from './MainView';

import './App.css';

class App extends Component {
render () {
  return (
    <div className="App" style={{ height: "100%", width: "100%" }}>
    <Router>
			<Switch>
				<Route exact path="/" component={MainView}/>
				<Route exact path="/about">About</Route>
				<Redirect to="/"/>
			</Switch>
    </Router>
    </div>
  );
  }
}

export default App;











import React, { Component } from 'react';
import { hot } from 'react-hot-loader'

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';

import qs from 'qs';

import './App.css';
import StreamInput from './StreamInput';
import Tile from './Tile';
import logo from './logo.png'

class MainView extends Component {
	constructor() {
		super();
		
		this.state = {
			selectedTile: null,
			clickedTile: null,
			url1: null,
			url2: null,
			url3: null,
			url4: null,
			shouldShowHelp: false,
			shouldShowShare: false
		}
	}

	handleSelectTile = selectedTile => {
		if (this.state.clickedTile) {
			return;
		}
		this.setState({selectedTile}, () => {});
	}
  
	handleClickTile = clickedTile => {
		if (this.state.clickedTile === clickedTile) {
				this.setState({clickedTile: null});
		} else {
			this.setState({clickedTile}, () => {});
			this.setState({selectedTile: clickedTile});
		}
	}
	
	getButtonState = id => {
		if (this.state.forcedTile === id) {
			return "primary";
		} else if (this.state.hoveredTile === id ) {
			return "secondary"
		} else {
			return "outline-secondary";
		}
	}
		
	updateURLBar = () => {
		var urlParams = this.makeURL();
		
		this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: urlParams
    });
	}
	
	setStreamURL = () => {
		var value1 = document.getElementById("StreamURL1").value;
		
		if (this.state.url1 !== value1) {
			this.setState({url1:value1}, () => {
				this.updateURLBar();
			});
		}
		
		var value2 = document.getElementById("StreamURL2").value;
		if (this.state.url2 !== value2) {
			this.setState({url2:value2}, () => {
				this.updateURLBar();
			});
		}
		
		var value3 = document.getElementById("StreamURL3").value;
		if (this.state.url3 !== value3) {
			this.setState({url3:value3}, () => {
				this.updateURLBar();
			});
		}
		
		var value4 = document.getElementById("StreamURL4").value;
		if (this.state.url4 !== value4) {
			this.setState({url4:value4}, () => {
				this.updateURLBar();
			});
		}
	}
	
	showHelp = () => {
		this.setState({shouldShowHelp: !this.state.shouldShowHelp});
	} 

	componentDidMount() {
    // Runs after the first render() lifecycle
    var s1 = qs.parse(this.props.location.search,{ignoreQueryPrefix: true}).s1
		var s2 = qs.parse(this.props.location.search,{ignoreQueryPrefix: true}).s2
		var s3 = qs.parse(this.props.location.search,{ignoreQueryPrefix: true}).s3
		var s4 = qs.parse(this.props.location.search,{ignoreQueryPrefix: true}).s4
		var active = qs.parse(this.props.location.search,{ignoreQueryPrefix: true}).active
				
		if (active) {
			this.setState({selectedTile: Number(active)});
		}
		
		if (s1) {
		this.setState({url1: s1})
		document.getElementById("StreamURL1").value = s1;
		}
		
		if (s2) {
		this.setState({url2: s2})
		document.getElementById("StreamURL2").value = s2;
		}
		
		if (s3) {
		this.setState({url3: s3})
		document.getElementById("StreamURL3").value = s3;
		}
		
		if (s4) {
		this.setState({url4: s4})
		document.getElementById("StreamURL4").value = s4;
		}

  }
  
  makeURL = () => {
  	var url = "?";
  	if (this.state.url1) {
  		var url1 = encodeURIComponent(this.state.url1)
  		url += `s1=${url1}&`	
  	}
		if (this.state.url2) {
  		var url2 = encodeURIComponent(this.state.url2)
  		url += `s2=${url2}&`	
  	}
  	if (this.state.url3) {
  		var url3 = encodeURIComponent(this.state.url3)
  		url += `s3=${url3}&`	
  	}
  	if (this.state.url4) {
  		var url4 = encodeURIComponent(this.state.url4)
  		url += `s4=${url4}&`	
  	}
  	if (this.state.selectedTile) {
  		//url+=`active=${this.state.selectedTile}`
  	}
  	
  	if (url.endsWith("&", url.length)) {
  		url = url.slice(0, url.length - 1);
  	}
  	
  	return url;
  }
  
  makeFullURL = () => {
  	return `fourstream.tv/${this.makeURL()}`
  }
  
  toggleShare = () => {
  	this.setState({shouldShowShare: !this.state.shouldShowShare})
  }
  
	render () {
		
		const popover = (
			<Popover id="popover-basic">
				<Popover.Title as="h3">Direct Link</Popover.Title>
				<Popover.Content>
					<Form.Control type="text" placeholder={this.makeFullURL()} readOnly/>
				</Popover.Content>
			</Popover>
			);

		return (
			<>
				<Navbar bg="light" expand="lg">
					<Navbar.Brand  style={{ paddingTop: 0, paddingBottom: 0 }} > <img src={logo}/> </Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
  				<Navbar.Collapse id="basic-navbar-nav">
						<StreamInput id={1} 
													shouldShowHelp={this.state.shouldShowHelp} 
													clickedTile={this.state.clickedTile} 
													selectedTile={this.state.selectedTile} 
													url={this.state.url1}  
													handleClickTile={() => this.handleClickTile(1)}  
													setStreamUrl={() => this.setStreamURL()}
						/>
						<StreamInput id={2} 
													shouldShowHelp={this.state.shouldShowHelp}
													clickedTile={this.state.clickedTile} 
													selectedTile={this.state.selectedTile} 
													url={this.state.url2}  
													handleClickTile={() => this.handleClickTile(2)}  
													setStreamUrl={() => this.setStreamURL()}
						/>
						<StreamInput id={3} 
													shouldShowHelp={this.state.shouldShowHelp}
													clickedTile={this.state.clickedTile} 
													selectedTile={this.state.selectedTile} 
													url={this.state.url3} 
													handleClickTile={() => this.handleClickTile(3)}  
													setStreamUrl={() => this.setStreamURL()}
						/>
						<StreamInput id={4} 
													shouldShowHelp={this.state.shouldShowHelp}
													clickedTile={this.state.clickedTile} 
													selectedTile={this.state.selectedTile} 
													url={this.state.url4}  
													handleClickTile={() => this.handleClickTile(4)}  
													setStreamUrl={() => this.setStreamURL()}
						/>
						<OverlayTrigger placement="left-end" show={this.state.shouldShowHelp} overlay={(<Tooltip id="help-tooltip">HALP</Tooltip>)}>				
							<Button 
								style={{borderRadius: "50%", 
												height: "25px", 
												width: "25px", 
												lineHeight: "0.8",
												display: "flex", 
												justifyContent: "center"
								}} 
								className="ml-auto" 
								type="button" 
								variant={this.state.shouldShowHelp === true ? "primary" : "outline-primary"} 
								id="help" onClick={() => this.showHelp()} 
							>?</Button>
  					</OverlayTrigger>

 					</Navbar.Collapse>
				</Navbar>
		
				<Container fluid className="d-flex flex-column " style={{ height: `calc(100% - 56px)`, paddingLeft: 0, paddingRight: 0 }}>
					<Row className="h-100" style={{ marginLeft: 0, marginRight: 0 }}>
						<Col className="w-100" style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Tile streamUrl={this.state.url1} isSelected={this.state.selectedTile === 1} key={1} id={1} onHover={() => this.handleSelectTile(1)} />
						</Col>
						<Col className="w-100" style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Tile streamUrl={this.state.url2} isSelected={this.state.selectedTile === 2} key={2} id={2} onHover={() => this.handleSelectTile(2)} />
						</Col>
					</Row>
					<Row className="h-100"  style={{ marginLeft: 0, marginRight: 0 }}>
						<Col className="w-100" style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Tile streamUrl={this.state.url3} isSelected={this.state.selectedTile === 3} key={3} id={3} onHover={() => this.handleSelectTile(3)} />
						</Col>
						<Col className="w-100" style={{ paddingLeft: 0, paddingRight: 0 }}>
							<Tile streamUrl={this.state.url4} isSelected={this.state.selectedTile === 4} key={4} id={4} onHover={() => this.handleSelectTile(4)} />
						</Col>
					</Row>
				</Container>
			</>
		)
	}
}

export default hot(module)(MainView)

/*
//help button if I need it again
						<OverlayTrigger placement="bottom" show={this.state.shouldShowHelp} overlay={(<Tooltip id="share-tooltip">Get a direct link to the streams you're currently viewing.</Tooltip>)}>
						<OverlayTrigger placement="bottom" show={this.state.shouldShowShare} overlay={popover}>			
							<Button className="ml-auto" type="button" variant="outline-primary" id="share" onClick={() => this.toggleShare()} >Share</Button>
  					</OverlayTrigger>
  					</OverlayTrigger>
*/
import React, { Component } from 'react';
import { hot } from 'react-hot-loader'

import Player from './Player';

const baseTileStyles = {
  backgroundColor: "lightgray",
  transition: "ease 500ms background-color",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height:"100%",
  width:"100%"
};

const selectedStyles = {
  ...baseTileStyles,
  backgroundColor: "gray",
  height:"100%",
  width:"100%"
};

const getTileStyle = isSelected => {
  return isSelected ? selectedStyles : baseTileStyles;
};

class Tile extends Component {
  render () {
  	return (
  		<div onMouseEnter={() => this.props.onHover(this.props.id)} style={getTileStyle(this.props.isSelected)}>
    		<Player streamUrl={this.props.streamUrl} isSelected={this.props.isSelected}/>
  		</div>
  	)
  }
}


export default hot(module)(Tile)
import React, { Component } from 'react';
import { hot } from 'react-hot-loader'
import ReactPlayer from 'react-player'

import './Player.css';


class Player extends Component {
	render () {
		return (

				<div style={{ height: "100%", width: "100%" }}>
					<ReactPlayer
						className='react-player'
						width='100%'
						height='100%'
						url={this.props.streamUrl}
						muted={!this.props.isSelected}
						playing={true}
					/>
				</div>	
		)
	}
}

export default hot(module)(Player)